<template>
  <div class="app-container">
    <!--巡检查询-->
    <!-- <el-tabs
      v-model="activeTabName"
      type="card"
      @tab-remove="removeTab"
      class="app-container-top"
    >
      <el-tab-pane
        :label="$t('inspection.list')"
        :name="listTab.tabName"
        :key="listTab.tabName"
      > -->
    <div style="margin: 20px">
      <el-form role="form" label-width="100px" class="app-container-form">
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('base.company.name')">
              <el-select
                @change="changeCompany"
                v-model="listQuery.companyId"
                @clear="clearEmpty('companyId')"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="item in companyList"
                  :label="item.companyName"
                  :key="item.companyId"
                  :value="item.companyId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('base.project.name')">
              <el-select
                :disabled="projectAble"
                v-model="listQuery.projectId"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.projectId"
                  :value="item.projectId"
                  :label="item.projectName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('inspection.plan.executeDept')">
              <el-select
                @change="changeDept"
                :disabled="deptAble"
                v-model="listQuery.deptId"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="item in departmentList"
                  :key="item.deptId"
                  :value="item.deptId"
                  :label="item.deptName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('inspection.plan.executeDuty')">
              <el-select
                :disabled="dutyAble"
                v-model="listQuery.jobDutyId"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="item in dutyList"
                  :key="item.dutyId"
                  :value="item.dutyId"
                  :label="item.dutyName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('inspection.plan.executePerson')">
              <el-select
                v-model="listQuery.jobPersonId"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="item in employeeList"
                  :key="item.userId"
                  :value="item.userId"
                  :label="item.userName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('commons.startTime')">
              <el-date-picker
                @change="pickerStart"
                v-model="listQuery.startTime"
                style="width: 100%"
                type="datetime"
                placeholder="计划开始时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item :label="$t('commons.endTime')">
              <el-date-picker
                @change="pickerEnd"
                v-model="listQuery.endTime"
                style="width: 100%"
                type="datetime"
                placeholder="计划结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('inspection.cycle')">
              <el-select
                v-model="listQuery.cycleType"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  :value="1"
                  :label="$t('commons.monthly')"
                ></el-option>
                <el-option :value="2" :label="$t('commons.weekly')"></el-option>
                <el-option :value="3" :label="$t('commons.daily')"></el-option>
                <el-option :value="4" :label="$t('commons.each')"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('inspection.plan.name')">
              <el-input
                v-model="listQuery.inspectionJobName"
                maxlength="16"
                clearable
                @keyup.enter.native="searchCondition"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('inspection.code')">
              <el-input
                v-model="listQuery.inspectionJobCode"
                maxlength="16"
                clearable
                @keyup.enter.native="searchCondition"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('inspection.status')">
              <el-select
                v-model="listQuery.statusArrs"
                clearable
                multiple
                collapse-tags
                :style="{ width: '100%' }"
              >
                <el-option
                  :value="1"
                  :label="$t('order.waitReceive')"
                ></el-option>
                <el-option
                  :value="2"
                  :label="$t('order.waitExecute')"
                ></el-option>
                <el-option
                  :value="3"
                  :label="$t('order.executing')"
                ></el-option>
                <el-option
                  :value="4"
                  :label="$t('order.completed')"
                ></el-option>
                <el-option :value="5" label="已关单"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          
          <el-col :span="6">
            <el-form-item label="是否超时">
              <el-select  @change='searchCondition' v-model="listQuery.isOverTime" :style="{ width: '100%' }" >
                <el-option value="" label="全部"></el-option>
                <el-option value="N" label="否"></el-option>
                <el-option value="Y" label="是"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
        	<el-col :span="6">
            <el-form-item label="生成开始时间">
              <el-date-picker
                v-model="listQuery.creatStartTime"
                style="width: 100%"
                type="datetime"
                placeholder="生成开始时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="生成结束时间">
              <el-date-picker
                v-model="listQuery.creatEndTime"
                style="width: 100%"
                type="datetime"
                placeholder="生成结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6" :style="{ paddingLeft: '30px' }">
            <el-button
              type="primary"
              icon="el-icon-search"
              :loading="loading"
              @click="searchCondition"
              >{{ $t("commons.search") }}</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-brush"
              plain
              @click="clearForm"
              >{{ $t("commons.clear") }}</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-download"
              plain
              @click="exportInspection"
              >{{ $t("commons.export") }}</el-button
            >
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-bottom: 12px">
        <el-button type="primary" plain size="mini" @click="closeInspection()"
          >批量关单</el-button
        >
      </div>

      <el-table
        ref="myRefTable"
        v-loading="loading"
        element-loading-text="Loading"
        :data="list"
        border
        fit
        stripe
        highlight-current-row
      >
        <el-table-column
          type="selection"
          width="50"
          :selectable="isAllowCheck"
        ></el-table-column>
        <el-table-column
          :label="$t('inspection.plan.inspectionName')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.inspectionJobName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('inspection.code')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.inspectionJobCode }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('inspection.plan.jobCycle')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.cycleName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('inspection.plan.executeDept')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
          	<!--如果deptName有的话就直接取值  诺没有的话 就遍历jobDeptList取deptName   原因是兼顾以前历史数据-->
            <!--<span v-if='s.row.deptName'>{{ s.row.deptName }}</span>-->
            <span v-for="(it,dex) in s.row.jobDeptList" :key='dex'><span v-if="dex>0">/</span> {{it.deptName}}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('inspection.plan.executeDuty')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>
            	<template v-for="(item, index) in scope.row.jobDutyList">
              		<span v-if="index>0">/</span>
                {{ item.dutyName }}
              </template>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('commons.actualStartTime')"
          :show-overflow-tooltip="true"
          width="160"
        >
          <template slot-scope="s">
            <span>{{
              s.row.actualStartDate | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('commons.actualEndTime')"
          :show-overflow-tooltip="true"
          width="160"
        >
          <template slot-scope="s">
            <span>{{
              s.row.finishDate | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('inspection.plan.executePerson')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.handlePersonName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('inspection.status')"
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.statusName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="超时时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ timeDiffRender(s.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('order.generateTime')"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.creationDateStr }}</span>
          </template>
        </el-table-column>
        
        
        <el-table-column
          label="计划开始时间"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.startTimeStr }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="计划结束时间"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.endTimeStr }}</span>
          </template>
        </el-table-column>
        <el-table-column
        	fixed='right'
          :label="$t('commons.actions')"
          align="center"
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <el-button
              type="text"
              @click="showInspectionDetail(s.row.inspectionJobId)"
              >详情</el-button
            >
            <el-button
              type="text"
              :disabled="s.row.status == 4 || s.row.status == 5"
              @click="closeInspection(s.row)"
              >关单</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.current"
        :limit.sync="listQuery.rowCount"
        @pagination="getInspectionQueryPage"
      />
    </div>
    <!-- </el-tab-pane>
      <el-tab-pane
        v-if="detailTab"
        :label="$t('inspection.details')"
        :name="detailTab.tabName"
        :key="detailTab.tabName"
        closable
      >
        <inspection-detail
          :inspectionJobId="inspection.inspectionJobId"
          :key="inspection.inspectionJobId"
        ></inspection-detail>
      </el-tab-pane>
    </el-tabs> -->

    <el-dialog
      class="closeInspPup"
      title="巡检关单"
      :visible.sync="closeRowInfo.isOpen"
      width="800px"
      :close-on-click-modal="false"
    >
      <div>
        <div class="c_title">请输入关单原因</div>
        <div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="closeRowInfo.closeReason"
            maxlength="150"
            show-word-limit
          >
          </el-input>
        </div>
        <div class="tips">
          关单成功后状态将变更为已关单，且无法撤回，请认真检查已选项是否无误
        </div>
      </div>

      <div class="btmBtn">
        <el-button class="searchbtn" @click="closeRowInfo.isOpen = false">
          取消</el-button
        >
        <el-button
          type="primary"
          :disabled="!closeRowInfo.closeReason || isCloseing"
          @click="submitCloseInfo"
        >
          提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCompanyList,
  getEmployeeList,
  getProjectList,
  getResDeptName,
} from "@/api/business/order/tenant/order";
import store from "@/store/index";
import {
  getInspectionQueryPage,
  getDutyName,
  exportInspection,
} from "@/api/business/inspection/tenant/query";
import Pagination from "@/components/Pagination";
import { differenceInDays, intervalToDuration, addMinutes } from "date-fns";
import InspectionDetail from "@/views/business/inspection/query/tenant/inspectionDetail/inspectionDetail.vue";

const defineTab = {
  listTab: {
    tabName: "1",
  },
  detailTab: {
    tabName: "2",
  },
};

export default {
  name: "inspection-query",
  components: { Pagination, InspectionDetail },
  data() {
    return {
      activeTabName: defineTab.listTab.tabName,
      detailTab: null,
      loading: true,
      queryDialog: false,
      projectAble: true,
      deptAble: true,
      dutyAble: true,
      companyList: [],
      employeeList: [],
      projectList: [],
      departmentList: [],
      dutyList: [],
      dynamicList: [],
      list: null,
      total: 0,
      pickerOpts: {
        // 默认日期
        pickerData: undefined,
      },
      listQuery: {
        current: 1,
        rowCount: 10,
        companyId: null,
        projectId: null,
        inspectionJobName: null,
        cycleType: null,
        startTime: null,
        endTime: null,
        creatEndTime: null,
        creatStartTime: null,
        isOverTime:'',
        deptId: null,
        jobDutyId: null,
        jobPersonId: null,
        status: null,
        statusArrs:[],
        inspectionJobCode: null,
      },
      inspection: {
        inspectionJobId: null,
      },
      isCloseing: false,
      closeRowInfo: {
        ids: [],
        closeReason: "",
        isOpen: false,
      },
    };
  },
  created() {
  	this.getUrlQuerys();
    this.searchCondition();
    this.getCompanyList();
    this.getEmployeeList();
//  const { query } = this.$route;
//  if (query.inspectionJobId) {
//    this.$nextTick(() => {
//      console.log("11111111", query.inspectionJobId);
//      this.showInspectionDetail(+query.inspectionJobId);
//    });
//  }
  },
  computed: {
    listTab() {
      return defineTab.listTab;
    },
  },
  methods: {
    handleDel() {},
		getUrlQuerys(){
  		//获取连接上携带的参数
  		const qData=this.$route.query;
  		if (qData.inspectionJobId) {
	      this.$nextTick(() => {
	        this.showInspectionDetail(+qData.inspectionJobId);
	      });
	      return;
    	}
  		if(qData.isOverTime){
  			this.listQuery.isOverTime=qData.isOverTime;
  		}
  		
			if(qData.bmsType){
				const {startDate,endDate,statuArrs}= qData;
				if(startDate&&endDate){
					console.log('5555',qData)
					this.listQuery.creatStartTime=new Date(startDate).getTime();
					this.listQuery.creatEndTime=new Date(endDate).getTime();
				}
				if(statuArrs){
					this.listQuery.statusArrs = JSON.parse(statuArrs);
				}
				if(qData.projectId){
	  			let cpId=qData.companyId?Number(qData.companyId):4;//当前需求 只有锦绣有  BMS没有这个概念
	  			this.listQuery.companyId=cpId;
	  			this.changeCompany(cpId);
	  			this.listQuery.projectId=+qData.projectId;
	  		}
			}
  	},
  	timeDiffRender(item) {
      // 任务状态（1：待领取，2：待执行，3：执行中，4：已完成）
      let str = "";
      let i = null;
      if (typeof item.status === "number") {
        switch (item.status) {
          case 2:
          
          	if(item.executedNumber){
      		 		const firstExecutedOvertime =
          		typeof item.firstExecutedOvertime === "number"
	                ? item.firstExecutedOvertime
	                : 0;
	            const s = new Date(item.lastUpdateDate);
	            const expire = addMinutes(s, firstExecutedOvertime);
	            i = {
	              start: new Date(expire),
	              end: new Date(),
	            };
	            str += "超时";
          		
          	}
            // if (this.checkExpire(item)) {
            // if (typeof item.lastUpdateDate === "number") {
           
            // }
            // } else {
            //   if (item.endTime) {
            //     i = {
            //       start: new Date(),
            //       end: new Date(item.endTime),
            //     };
            //     str += "剩余";
            //   }
            // }
            break;
          case 3:
            // if (this.checkExpire(item)) {
        		//          	completeNumber  执行中超时次数 执行中的工单截止要求完成时间还未提交视为一次超时，之后每过 N 分钟视为一次超时。
             if (item.completeNumber&&item.endTime) {
              i = {
                start: new Date(item.endTime),
                end: new Date(),
              };
              str += "超时";
            }
            // } else {
            //   if (item.endTime) {
            //     i = {
            //       start: new Date(),
            //       end: new Date(item.endTime),
            //     };
            //     str += "剩余";
            //   }
            // }
            break;
          case 1:
            // if (this.checkExpire(item)) {
        		//          	unclaimedNumber  待领取超时次数 待领取状态下的工单如果未被领取，每  N 分钟视为一次超时。
            if (item.unclaimedNumber&&item.creationDate) {
            	//待领取状态下的工单如果未被领取，每 N 分钟视为一次超时
            	
              const unclaimedOvertime =
                typeof item.unclaimedOvertime === "number"
                  ? item.unclaimedOvertime
                  : 0;
              const s = new Date(item.creationDate).getTime();
              const expire = addMinutes(s, unclaimedOvertime);
              i = {
                start: new Date(expire),
                end: new Date(),
              };
              str += "超时";
            }
            // } else {
            //   if (item.startTime) {
            //     i = {
            //       start: new Date(item.startTime),
            //       end: new Date(),
            //     };
            //     str += "已生成";
            //   }
            // }
            break;
        }
      }

      if (i) {
        const m = new Date(i.end).getTime - new Date(i.start).getTime();
        if (m < 1) {
          str += "0分";
        } else {
          const d = intervalToDuration(i);
          const days = differenceInDays(i.end, i.start);

          if (days > 0) {
            str += `${days}天`;
          }
          if (d.hours && d.hours > 0) {
            str += `${d.hours}小时`;
          }
          if (d.minutes && d.minutes > 0) {
            str += `${d.minutes}分`;
          }
        }
      }

      return str;
    },
    closeInspection(row) {
      let ids = [];
      if (row) {
        ids.push(row.inspectionJobId);
      } else {
        let rowArr = this.$refs.myRefTable.selection;
        rowArr.map((it) => {
          ids.push(it.inspectionJobId);
        });
      }

      console.log("ids", ids);
      if (ids.length < 1) {
        this.$message({ type: "warning", message: "请选择" });
      } else {
        this.closeRowInfo = {
          ids: ids,
          isOpen: true,
          closeReason: "",
        };
      }
    },
    async submitCloseInfo() {
      console.log("closeRowInfo", this.closeRowInfo);
      this.isCloseing = true;

      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        closeReason: this.closeRowInfo.closeReason,
        inspectionJobIdList: this.closeRowInfo.ids,
      };
      let res = await this.ApiHttp(
        "/inspection/tenant/inspection/job/closeInspectionJob",
        params,
        "post"
      );
      loading.close();
      setTimeout(() => {
        this.isCloseing = false;
      }, 1500);

      this.$message({ type: "success", message: "操作成功" });
      this.closeRowInfo.isOpen = false;
      this.searchCondition();
    },
    isAllowCheck(row, index) {
      let num = Number(row.status);
      if (num == 4 || num == 5) {
        return false;
      } else {
        return true;
      }
    },
    // 关闭项Tab
    removeTab(tabName) {
      this.activeTabName = defineTab.listTab.tabName;
      if (tabName == "2") {
        this.detailTab = null;
      }
    },
    // 展示巡检详情
    showInspectionDetail(inspectionJobId) {
      // 打开tab
      // this.detailTab = defineTab.detailTab;
      this.inspection.inspectionJobId = inspectionJobId;
      // this.activeTabName = defineTab.detailTab.tabName;
      if (location.hash.includes("/bms")) {
        this.$router.push({
          path: "/bms/inspectionDetail",
          query: {
            inspectionJobId,
          },
        });
      } else {
        this.$router.push({
          path: "/tenant/inspection/inspectionSearch/detail",
          query: {
            inspectionJobId,
          },
        });
      }
    },
    pickerStart(time) {
    	if(time){
    		this.listQuery.startTime = time.getTime();
    	}
      
    },
    pickerEnd(time) {
    	if(time){
    		 this.listQuery.endTime = time.getTime();
    	}
    },
    getCompanyList() {
      getCompanyList().then((res) => {
        this.companyList = res;
      });
    },
    getInspectionQueryPage() {
      
      let params = JSON.parse(JSON.stringify(this.listQuery));
      params.planType=1;
     	params.statusListStr=params.statusArrs.join(';');
     	delete params.statusArrs;
     	if(params.startTime&&params.endTime){
     		let isAllow1=this.common.correctTimeParams(params.startTime,params.endTime);
     		console.log('isAllow1',isAllow1)
     		if(!isAllow1.status){
     			this.$message({type:'warning',message:'请检查计划时间是否选择正确'});
     			return;
     		}
     	}
     	if(params.creatStartTime&&params.creatEndTime){
     		let isAllow2=this.common.correctTimeParams(params.creatStartTime,params.creatEndTime);
     		if(!isAllow2.status){
     			this.$message({type:'warning',message:'请检查生成时间是否选择正确'});
     			return;
     		}
     	}
     	if(params.creatStartTime){
     		params.creatStartTime=new Date(params.creatStartTime).getTime();
     	}
     	if(params.creatEndTime){
     		params.creatEndTime=new Date(params.creatEndTime).getTime();
     	}
      for (let keys in params) {
        if (params[keys] === "") params[keys] = null;
      }
      this.loading = true;
      getInspectionQueryPage(params)
        .then((res) => {
          this.list = res.rows.map((v) => {
            return v;
          });
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchCondition() {
      this.listQuery.current = 1;
      this.getInspectionQueryPage();
    },
    clearForm() {
      (this.projectAble = true),
        (this.deptAble = true),
        (this.dutyAble = true),
        (this.listQuery.companyId = null),
        (this.listQuery.projectId = null),
        (this.listQuery.inspectionJobName = null),
        (this.listQuery.cycleType = null),
        (this.listQuery.startTime = null),
        (this.listQuery.endTime = null),
        (this.listQuery.creatStartTime = null),
        (this.listQuery.creatEndTime = null),
        (this.listQuery.deptId = null),
        (this.listQuery.jobDutyId = null),
        (this.listQuery.jobPersonId = null),
        (this.listQuery.status = null);
        (this.listQuery.statusArrs = []);
        (this.listQuery.isOverTime = '');
        
        
    },
    getEmployeeList() {
      getEmployeeList().then((res) => {
        this.employeeList = res;
      });
    },
    changeCompany(companyId) {
    	console.log('changeCompany',companyId)
      if (companyId) {
        this.deptAble = false;
        this.projectAble = false;
        this.listQuery.projectId = null;
        this.listQuery.deptId = null;
        this.listQuery.jobDutyId = null;
        this.dutyAble = true;
        getProjectList({ companyId: companyId }).then((res) => {
          this.projectList = res;
        });

        getResDeptName({ companyId: companyId }).then((res) => {
          this.departmentList = res;
        });
      } else {
        this.departmentList = [];
        this.projectList = [];
        this.listQuery.projectId = null;
        this.listQuery.deptId = null;
        this.listQuery.jobDutyId = null;
        this.dutyAble = true;
        this.deptAble = true;
        this.projectAble = true;
      }
    },
    changeDept(id) {
      this.dutyAble = false;
      getDutyName({ deptId: id }).then((res) => {
        this.dutyList = res;
      });
    },
    exportInspection() {
    	let params=JSON.parse(JSON.stringify(this.listQuery));
    	if(params.creatStartTime){
     		params.creatStartTime=new Date(params.creatStartTime).getTime();
     	}
     	if(params.creatEndTime){
     		params.creatEndTime=new Date(params.creatEndTime).getTime();
     	}
    	params.statusListStr=params.statusArrs.join(';');
    	delete params.statusArrs;
      exportInspection(params).then((msg) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId: msg,
          taskName: "InspectionQuery",
          taskStatus: 0,
          rootPath: "inspectionPath",
        };
        //将导出任务丢入导出任务列表中
        store.dispatch("PushExportNotice", exportObj);
      });
    },
    clearEmpty(field) {
      this.listQuery[field] = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  background: #ffffff;
  padding: 0;

  &-top {
    background: #ffffff;
  }
}
::v-deep .el-tabs__header {
  margin: 0;
}

::v-deep .app-container-form {
  margin-top: 20px;
}
</style>
<style  lang="scss">
.closeInspPup {
  .el-dialog__header {
    padding: 18px 28px;
    padding-bottom: 0;
  }
  .c_title {
    color: #000000;
    padding: 16px 0;
  }
  .tips {
    font-size: 12px;
    color: #999;
    line-height: 18px;
    padding: 10px 0;
  }
  .btmBtn {
    padding: 18px 0;
    text-align: right;
  }
}
</style>